import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIs, environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})

export class AwardService {
  user_id = JSON.parse(localStorage.getItem('user_id'))
  constructor(private http: HttpClient) { }
  awards(id, role = ' ', status, filter?) {
    let params: any = new HttpParams()
      .set("client_id", id)
      .set("role", role)
      .set("status", status);
    if (filter) {
      Object?.entries(filter)?.forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          params = params.set(key, value);
        }
      });
    }
    return this.http.get(APIs.getAward, { params: params });
  }

  getSpecificFilterData() {
    return this.http.get(
      environment.laravelApiUrl + `hr/get_specific_filter_data?module=award&emp_id=${this.user_id}`
    )
    // .pipe(catchError(this.handleError));
  }
  getFiscalYearList() {
    return this.http.get(`${environment.laravelApiUrl}performancereward/award/getFiscalYearDropDown`)
  }
  ceoAwardList(id) {
    const params: any = new HttpParams().set("client_id", id);
    return this.http.get(APIs.getCeoAward, { params: params });
  }
  departments(id, portalId?, proposedDpt?) {
    let params
    params = new HttpParams().set("client_id", id)
    if (portalId) {
      params = new HttpParams().set("client_id", id).set("portal_id", portalId).set("proposed", proposedDpt);
    }
    if (portalId && proposedDpt == undefined) {
      params = new HttpParams().set("client_id", id).set("portal_id", portalId)
    }
    return this.http.get(APIs.getDepartment, { params: params });
  }
  designations(id, role) {
    let params
    params = new HttpParams().set("department_id", id).set("role", role)
    return this.http.get(APIs.getDesignationOnDepartment, { params: params });
  }
  // designations(id, portalId?, proposedDes?) {
  //   let params
  //   params = new HttpParams().set("client_id", id)
  //   if (portalId) {
  //     params = new HttpParams().set("client_id", id).set("portal_id", portalId).set("proposed", proposedDes);
  //   }
  //   if (portalId && proposedDes == undefined) {
  //     params = new HttpParams().set("client_id", id).set("portal_id", portalId)
  //   }
  //   return this.http.get(APIs.getDesignation, { params: params });
  // }
  /**
   * 
   * @param id 
   * @returns 
   */
  awardTypes(id) {
    const params: any = new HttpParams().set("client_id", id);
    return this.http.get(APIs.getAwardType, { params: params });
  }
  viewAttachment(id, role?) {
    const params: any = new HttpParams().set("id", id).set('role', role);
    return this.http.get(APIs.attachment, { params: params });
  }
  employeeForAwards(dept_id, desig_id, role) {
    const params: any = new HttpParams()
      .set("department_id", dept_id)
      .set("designation_id", desig_id)
      .set("role", role);
    return this.http.get(APIs.getNameForAwards, { params: params });
  }
  employeeAwards(id, role) {
    const params: any = new HttpParams().set("id", id).set("role", role);
    return this.http.get(APIs.getEmployeeAwards, { params: params });
  }
  postAward(payload) {
    return this.http.post(APIs.postAward, payload);
  }
  postAwardIssueLetter(payload) {
    return this.http.post(APIs.postAwardLetter, payload);
  }
  awardStatus(id, role = '') {
    const params: any = new HttpParams().set("client_id", id).set("role", role);
    return this.http.get(APIs.getStatus, { params: params });
  }
  withdrawApplication(appId, clientId, role) {
    const params: any = new HttpParams()
      .set("application_id", appId)
      .set("client_id", clientId)
      .set("role_id", role);
    return this.http.get(APIs.withdraw, { params: params });
  }
  changeStatus(payload) {
    return this.http.post(APIs.changeStatus, payload);
  }
  changeBulkStatus(payload) {
    return this.http.post(APIs.getBulkApproval, payload);
  }
  chartData(id) {
    const params: any = new HttpParams().set("client_id", id);
    return this.http.get(APIs.chartData, { params: params });
  }
  chartData1(client_id, emp_id) {
    const params: any = new HttpParams()
      .set("client_id", client_id)
      .set("emp_id", emp_id);
    return this.http.get(APIs.chartData, { params: params });
  }
  uploadFile(file) {
    return this.http.post(APIs.uploadFile, file);
  }
  templateDownload() {
    return APIs.templateDownload;
  }
  approvalQueue(clientId, appId) {
    const params: any = new HttpParams()
      .set("client_id", clientId)
      .set("application_id", appId);
    return this.http.get(APIs.approvalQueueAwards, { params: params });
  }
}
