import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewProfileService {
  newPersonalProfile = new BehaviorSubject<any>({});
  newContactDetails = new BehaviorSubject<any>({});
  newEducationDetails = new BehaviorSubject<any>({});
  newWorkExperience = new BehaviorSubject<any>({});
  newOtherDetails = new BehaviorSubject<any>({});
  newReferences = new BehaviorSubject<any>({});
  newBankDetails = new BehaviorSubject<any>({});
  newEmploymentDetails = new BehaviorSubject<any>({});
  personalInformation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  contactInformation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  educationDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  workExperience: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  otherDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  reference: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  bandDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  employementDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor() { }
}