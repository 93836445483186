import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  personalProfile = new BehaviorSubject<any>({});
  contactDetails = new BehaviorSubject<any>({});
  educationDetails = new BehaviorSubject<any>({});
  workExperience = new BehaviorSubject<any>({});
  otherDetails = new BehaviorSubject<any>({});
  references = new BehaviorSubject<any>({});
  bankDetails = new BehaviorSubject<any>({});
  employmentDetails = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) {

  }
}