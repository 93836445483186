import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from './form-error.component';

@NgModule({
  declarations: [FormErrorComponent],
  imports: [
    CommonModule
  ],
  exports: [FormErrorComponent]
})
export class FormErrorModule { }
