/**
 * @author Usman Ali <usman.ali@people.com.pk>
 */

export class DetectFormChangeValues {
 formKey = ((form: any): any => {
    const updatedFormValues = {};
    form["_forEachChild"]((control, name) => {
      if (control.dirty) {
        updatedFormValues[name] = control.value;
      }
    });
    return updatedFormValues;
  });
}
