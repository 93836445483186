import { Injectable } from "@angular/core";

//******************************       Declare Variables Start       ************************//
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


//******************************       Class Implementation Start       ************************//
@Injectable({
    providedIn: "root",
})
export class Helper {
    constructor() {}

    // Custom comparator function to compare dates in "dd-Mon-yyyy" format
    getDateValue = (dateString: string) => {
        const parts = dateString.split('-');
        const day = parseInt(parts[0]);
        const month = months.indexOf(parts[1]);
        const year = parseInt(parts[2]);
        return new Date(year, month, day).getTime(); // Convert to milliseconds for comparison
    };

}