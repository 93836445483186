import { Component, Input, OnInit,OnChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-donut-payroll-dashboard-chart',
  template: `<div style="margin-bottom:1px !important" [chart]="chart"></div>`
})
export class DonutPayrollDashboardChartComponent implements OnInit,OnChanges {
  @Input() chartTitle: any;
  @Input() chartColor: any;
  @Input() chartData: any;
  @Input() chartHeight: any;
  @Input() plotPosition : any
  @Input() textPosition : any
  @Input() centerLabel:any
  @Input() marginLeft:any
  @Input() innerSize: string;

  data = [];
  chart: Chart;
  count = '0'
  constructor() { }

  ngOnInit() {
    this.data = [];
    for (let i = 0; i < this.chartData.length; i++) {
      this.data.push(this.chartData[i]);
    }
    this.headCountChartDraw();
  }

  headCountChartDraw() {
    let chart = new Chart({
      chart: {
        type: 'pie',
        height:this.chartHeight ? this.chartHeight : 200,
        width : 289,
        marginLeft: this.marginLeft ? this.marginLeft : 0
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      title: {
        text: this.count,
        align: 'center',
        verticalAlign: 'middle',
        x: this.textPosition ? this.textPosition[0] : 0,
        y: this.textPosition ? this.textPosition[1] : 0,
        style: {
          fontSize: '20px',
          fontWeight : '400',
          color: '#343434',
        }
      },
      subtitle: {
        text: this.chartTitle,
        align: 'center',
        verticalAlign: 'middle',
        x: this.textPosition ? this.textPosition[0] : 0,
        y: this.textPosition ? this.textPosition[1] + 20 : 0,
        style: {
          fontSize: '12px',
          fontWeight : '400',
          color: '#343434'
        }
      },
      tooltip: {
        formatter: function () {
          return this.series.name + ': <b>' + this.y + '</b>';
        },
      },
      legend: {
        align: 'left',
        verticalAlign: 'bottom',
        layout: 'vertical',
        itemStyle: {
          color: '#343434',
        },
        symbolWidth: 8,
        symbolHeight: 8,
        //symbolPadding: 5,
        //y: 9
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          center: this.plotPosition ? this.plotPosition : [20, 70],
          dataLabels: {
            enabled: true,
            color: 'white',
            distance: -17,
            format: '{y}',
          },
          showInLegend: true,
          fillColor: '#EBF2F7',
          borderColor: '#EBF2F7',
          borderWidth: 0
        }
      },
      series: [
        {
          name: this.chartTitle,
          type: 'pie',
          colors: this.chartColor,
          data: this.data,
          size: '100%',
          innerSize: this.innerSize? this.innerSize: '92%',
          showInLegend:true,
          dataLabels: {
              enabled: false,
          },
          
        }
      ]

    });
    this.chart = chart;
  }
  ngOnChanges() {
    this.data = [];
    let count = 0
    for (let i = 0; i < this.chartData.length; i++) {
      count += this.chartData[i].y
      this.data.push(this.chartData[i]);
    }
    this.count = count.toString()
    this.headCountChartDraw();
  }
}

