import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { APIs } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class RosterLoginService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private httpService: HttpService
  ) {}

  login(form) {
    return this.http.post(APIs.rosterLogin, form);
  }

  checkLogin() {
    return JSON.parse(this.cookieService.get("tokken")).tokken;
  }

  forgetPassword(data: any) {
    return this.http.post<any>(APIs.forgetPassword, data);
  }

  generateOTP(data: any) {
    return this.http.post<any>(APIs.generateOTP, data);
  }

  verifyOtp(data: any) {
    return this.http.post<any>(APIs.verifyOtp, data);
  }

  changePassword(data: any) {
    return this.http.put<any>(APIs.changePassword, data);
  }
}
