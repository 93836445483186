import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlabDropdownComponent } from "./slab-dropdown.component";
import { MaterialModule } from "../material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ConfirmDialogModule } from "src/app/shared/modules/confirm-dialog/confirm-dialog.module";

@NgModule({
  declarations: [SlabDropdownComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
  ],
  exports: [SlabDropdownComponent],
})
export class SlabDropDownModule {}
