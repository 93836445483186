import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonutPayrollDashboardChartComponent } from './donut-payroll-dashboard-chart.component';
import { ChartModule } from 'angular-highcharts';

@NgModule({
  declarations: [DonutPayrollDashboardChartComponent],
  imports: [
    CommonModule,
    ChartModule,
  ],
  exports: [DonutPayrollDashboardChartComponent]
})
export class PayrollGoogleChartsModule { }
