import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIs } from "src/environments/environment";
import { HttpClient, HttpResponse } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class BankDisbursementService {
  constructor(private http: HttpClient) {}

  getAllPayrollQueue(body) {
    return this.http.get(
      `${APIs.getDisbursementQueueData}?filterMonth=${body.filterMonth}&filterYear=${body.filterYear}&createdBy=${body.createdBy}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  getDashboardStats(queueId) {
    return this.http.get(`${APIs.getDashboardStats}?queueId=${queueId}`, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  getBankWiseEmployeeCount(id, infoId, queueId, e_number) {
    return this.http.get(
      `${APIs.getBankWiseEmployeeCount}?clientId=${id}&infoId=${infoId}&queueId=${queueId}&createdBy=${e_number}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  processDisbursement(body) {
    return this.http.post(`${APIs.processDisbursement}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  updateEmployeesDisbursementStatus(body) {
    return this.http.post(`${APIs.updateEmployeesDisbursementStatus}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  getSpecificBankAllEmployees(body) {
    return this.http.post(`${APIs.getSpecificBankAllEmployees}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  getSpecificBankAllEmployeesFromSummary(body) {
    return this.http.post(
      `${APIs.getSpecificBankAllEmployeesFromSummary}`,
      body,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  getQueueTicketsForFilter() {
    return this.http.get(`${APIs.getQueueTicketsForFilter}`, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  viewSpecificBankQueueData(body) {
    return this.http.post(`${APIs.viewSpecificBankQueueData}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  startDisbursement(body) {
    return this.http.post(`${APIs.startDisbursement}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  sendApprovalToGroup2(body) {
    return this.http.post(`${APIs.sendApprovalToGroup2}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  disapproveDisbursement(body) {
    return this.http.post(`${APIs.disapproveDisbursement}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  startFileDisbursementProcess(body) {
    return this.http.post(`${APIs.startFileDisbursementProcess}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  markBankAsComplete(body) {
    return this.http.post(`${APIs.markBankAsComplete}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  checkForPendingChildQueue(body) {
    return this.http.post(`${APIs.checkForPendingChildQueue}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  startManualFlowDisbursement(body) {
    return this.http.post(`${APIs.startManualFlowDisbursement}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  startFileFlowDisbursement(body) {
    return this.http.post(`${APIs.startFileFlowDisbursement}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  getApprovalEmployees() {
    return this.http.get(`${APIs.getApprovalEmployees}`, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  getBankDocuments(data) {
    return this.http.get(
      `${APIs.getBankDocuments}?type=${data.type}&queueId=${data.queueId}&infoId=${data.infoId}&bankQueueId=${data.empQueueId}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }
  canManualRecordBeCompleted(data) {
    return this.http.get(
      `${APIs.canManualRecordBeCompleted}?queueId=${data.queueId}&infoId=${data.infoId}&bankQueueId=${data.empQueueId}&disbursementMethod=${data.disbursementMethod}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }
  belongsToBankDisbursement(createdBy) {
    return this.http.get(
      `${APIs.belongsToBankDisbursement}?createdBy=${createdBy}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }
  getApprovalSetup() {
    return this.http.get(`${APIs.getApprovalSetup}`, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  saveApprovalSetup(body) {
    return this.http.post(`${APIs.saveApprovalSetup}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  markMainQueueAsCompleted(body) {
    return this.http.post(`${APIs.markMainQueueAsCompleted}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  savePaymentEvidenceFiles(body) {
    return this.http.post(`${APIs.savePaymentEvidenceFiles}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  savePaymentEvidenceFilesBKIP(body) {
    return this.http.post(`${APIs.savePaymentEvidenceFilesBKIP}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }

  manualDisbursementThroughResponseFile(body) {
    return this.http.post(
      `${APIs.manualDisbursementThroughResponseFile}`,
      body,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  getCompletedMainQueueRecords(dateFilter, filterYear) {
    return this.http.get(
      `${APIs.getCompletedMainQueueRecords}?filterMonth=${dateFilter}&filterYear=${filterYear}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  getMarkedCompletedQueueDetail(queueId) {
    return this.http.get(
      `${APIs.getMarkedCompletedQueueDetail}?queueId=${queueId}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  getFilterData(queueId) {
    return this.http.get(`${APIs.getFilterData}?queueId=${queueId}`, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  checkForCheckerAuthorization(createdBy, callFrom?) {
    return this.http.get(
      `${APIs.checkForCheckerAuthorization}?createdBy=${createdBy}&callFrom=${
        callFrom ? "Pre" : ""
      }`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }
  getApprovalQueuePopupDetail(body) {
    return this.http.get(
      `${APIs.getApprovalQueuePopupDetail}?bankQueueId=${body.empQueueId}&cid=${body.cid}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }
  getErrorReportData(body) {
    return this.http.get(
      `${APIs.getErrorReportData}?queueId=${body.queueId}&infoId=${body.infoId}&bankQueueId=${body.empQueueId}&allEmp=${body.allEmp}&employeeId=${body.employeeId}&fromBankId=${body.fromBankId}&toBankId=${body.toBankId}&childGroupId=${body.childGroupId}&payrollSummaryError=${body.payrollSummaryError}`,
      {
        observe: "response",
      }
    ) as Observable<HttpResponse<any>>;
  }

  sendOtp(body) {
    return this.http.post(`${APIs.bd_sendOtp}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  verifyOtp(body) {
    return this.http.post(`${APIs.bd_verifyOtp}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  generateFinancialPin(body) {
    return this.http.post(`${APIs.generateFinancialPin}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  verifyFinancialPin(body) {
    return this.http.post(`${APIs.verifyFinancialPin}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
  checkForResponse(body) {
    return this.http.post(`${APIs.checkForResponse}`, body, {
      observe: "response",
    }) as Observable<HttpResponse<any>>;
  }
}
