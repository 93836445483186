import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { SlabService } from "./slab-dropdown.service";
import { ConfirmDialogService } from "src/app/shared/modules/confirm-dialog/confirm-dialog.service";

@Component({
  selector: "app-slab-dropdown",
  templateUrl: "./slab-dropdown.component.html",
  styleUrls: ["./slab-dropdown.component.css"],
})
export class SlabDropdownComponent implements OnInit {
  @Input("component") component;
  @Input("index") index;
  @Input("field") field;
  @Input("json") json;
  @Input("names") names;
  @Output() resultEvent? = new EventEmitter<string>();
  constructor(
    private slabstatus: SlabService,
    // private modalService: BsModalService,
    private ConfirmDialogService: ConfirmDialogService
  ) {}

  ngOnInit(): void {
  }
  editSlab() {
    this.slabstatus.sendStatus(true, this.index, this.field, this.component);
  }
  deactiveSlab() {
    this.ConfirmDialogService.confirmThis(
      "Are you sure to Deactivate this slab",
      () => {
        this.slabstatus.deactiveSlab(
          this.index,
          this.field,
          this.component,
          this.json
        );
        setTimeout(()=>{
          this.resultEvent.emit();
        }, 100)
      },
      () => {}
    );
  }

  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template);
  // }
}
